.scheduleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.scheduleTitle {
    margin-bottom: 5%;
    font-family: 'Poppins',sans-serif;
    font-weight: 900;
    font-size: clamp(1.5rem, 3vw, 3rem);
    font-style: italic;
    display: inline-block;
    color: white;
}

.scheduleButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.scheduleButton {
    border-radius: 100px;
    padding: 12px;
    margin: 4px 16px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.selectedButton {
    background-color: white;
    color: #24b2c0;
}

.unselectedButton {
    background-image: linear-gradient(to right, #24b2c0  0%, #0bd1d2 100%);
    color: white;
}

.scheduleButtonTitle {
    font-family: 'canada-type-gibson',sans-serif;
    font-weight: 600;
    font-size: clamp(1.5rem, 2vw, 2rem);
    display: inline-block;
    margin: 8px;
    text-align: center;
}

.scheduleList {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
}

.scheduleItem {
    margin: 8px 0;
}

.scheduleItemTitle {
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    font-size: clamp(1.5rem, 2vw, 2rem);
    display: inline-block;
    color: white;
    margin: 0;
}

.scheduleItemDescription {
    font-weight: 500;
    font-size: clamp(1.25rem, 1.75vw, 1.5rem);
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: white;
    margin: 0;
}

.scheduleImage {
    padding: 5%;
    width: 80%;
}

@media screen and (min-width: 600px) {
    .scheduleContainer {
        flex-flow: row wrap;
        justify-content: center;
        padding-left: 10%;
        padding-right: 10%
    }

    .scheduleTitle {
        margin-bottom: 2%;
        align-self: center;
    }

    .scheduleButtonContainer {
        flex-direction: row;
    }

    .scheduleButton {

        margin: 0 16px;
        width: 100%;
    }

    .scheduleList {
        width: 50%;
        align-self: flex-start;
    } 
    
    .scheduleImage {
        padding: 5%;
        width: 40%;
    }
}