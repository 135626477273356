.sponsorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sponsorLogoContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items:center;
    width: 100%;
}
    
.sponsorLogo {
    width: 90%;
    padding-bottom: 5%;
}

.sponsorsTitle {
    font-family: 'Poppins',sans-serif;
    font-weight: 900;
    font-size: clamp(1.5rem, 3vw, 3rem);
    font-style: italic;
    display: inline-block;
    color: white;

}

@media screen and (min-width: 600px) {
    .sponsorContainer {
        margin-top: 0;
        padding-left: 10%;
        padding-right: 10%;
    }

    .sponsorLogoContainer {
        flex-direction: row;
    }

    .sponsorLogo {
        width: 100%;
        height: auto;
        padding: 16px;
    }

    .sponsorLogo.conch {
        width: 100%;
        height:auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    
    .sponsorLogo.cone {
        width: 33%;
    }
    
    .sponsorLogo.scallop {
        width: 33%;
    }
    
    .sponsorLogo.sand_dollar {
        width: 25%;
    }
    
    .sponsorLogo.in-kind {
        width: 25%;
    }
    
    .bigLogo {
        max-width: 600px;
        align-self: center;
    }
}