.imageContainer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: auto;
    width: 101%;
}

@media screen and (max-width: 600px) {
    .imageContainer {
        display: none;
    }
}