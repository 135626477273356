.partnerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
}

.partnerLogoContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items:center;
    width: 100%;
}

.partnerLogo {
    width: 90%;
    padding-bottom: 5%;
}

.partnerTitle {
    font-family: 'Poppins',sans-serif;
    font-weight: 900;
    font-size: clamp(1.5rem, 3vw, 3rem);
    font-style: italic;
    display: inline-block;
    color: white;
}

@media screen and (min-width: 600px) {
    .partnerContainer {
        margin-top: 3%;
        padding-left: 10%;
        padding-right: 10%;
    }

    .partnerLogoContainer {
        flex-direction: row;
    }

    .partnerLogo {
        width: 25%;
        height: auto;
        padding: 16px;
    }
}