.infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
    margin-bottom: 10%;
    width: 100%;
}

.subheading {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: white;
    font-size: clamp(1.25rem, 1.75vw, 2rem);
    margin-top: 2%;
    margin-bottom: 2%;
}

.shellLogo {
    width: 80%;
    align-items: center;
    margin-bottom: 2%;
}

.buttonsDiv {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 70%;
}

.buttonRow {
    display: flex;
    flex-flow: row;
    justify-content: center;
}

.registerButton {
    color: rgb(255, 255, 255); 
    font-size: clamp(1.25rem, 1.75vw, 2rem); 
    line-height: 30px; 
    border-radius: 100px; 
    font-family: 'Poppins', sans-serif; 
    font-weight: normal; 
    text-align: center;
    text-decoration: none;
    background-image: linear-gradient(to right, rgb(255, 72, 142) 0%, rgb(255, 127, 148) 100%);
    border-color: transparent;
    margin-top: 5%;
    margin-left: 2%;
    margin-right: 2%;
    padding-top: 5%;
    padding-bottom: 5%;
    margin-bottom: 5%;
    font-weight: 800;
}
    
.myButton:hover {
    background: #FF488E; }
.myButton:active {
    background: #FF7F94; }


.rowButton {
    color: rgb(255, 255, 255); 
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    border-radius: 100px; 
    font-family: 'Poppins', sans-serif; 
    font-weight: normal; 
    text-align: center;
    text-decoration: none;
    background-image: linear-gradient(to right, #24b2c0  0%, #0bd1d2 100%);
    border-color: transparent;
    margin: 0 2%;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 50%;
    font-weight: 800;
}

@media screen and (min-width: 600px) {
    .infoContainer {
        margin-bottom: 2%;
        width: 40%;
    }

    .shellLogo {
        width: 100%;
    }

    .subheading {
        margin-top: 0.75%;
        margin-bottom: 0.75%;        
    }

    .buttonsDiv {
        width: 80%;
    }
}

@media screen and (min-width: 1200px) {
    .infoContainer {
        width: 35%;
    }
}