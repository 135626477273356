body, html {
    height: 100%;
    background-size: cover;
    margin:  0;
    overflow-x: hidden;
}

#root {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

@media screen and (max-width: 600px) {
    body, html {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        overflow-x: hidden;
    }

}