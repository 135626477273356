.trackContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    width: 100%;
}

.trackTitle {
    margin-bottom: 5%;
    font-family: 'Poppins',sans-serif;
    font-weight: 900;
    font-size: clamp(1.5rem, 3vw, 3rem);
    font-style: italic;
    display: inline-block;
    color: white;
}

.trackItemContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.track {
    flex-basis: 100%;
    margin: 5%;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
}

.trackItemTitle {
    font-family: 'Poppins',sans-serif;
    font-weight: 600;
    font-size: clamp(1.25rem, 1.75vw, 1.5rem);
    display: inline-block;
    color: white;
    margin-bottom: 2%;
}

.trackIcon {
    width: 60%;
}

.trackItemBody {
    font-weight: 500;
    font-size: clamp(1rem, 1.5vw, 1.25rem);
    font-family: 'Poppins', sans-serif;
    color: white;

    margin: 0;
}

.trackImage {
    width: 80%;
    margin: 2% 0;
}

@media screen and (min-width: 600px) {
    .trackItemContainer {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .trackTitle {
        margin-bottom: 0;
    }

    .track {
        flex-basis: 25%;
        margin: 2%;
    }

    .trackImage {
        width: 50%;
        margin: 2% 0;
    }
}

@media screen and (min-width: 1200px) {
}