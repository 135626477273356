.mlhBadge {
    display:block;
    max-width:100px;
    min-width:60px;
    width:10%;
    position:sticky;
    top: 0;
    margin-right: 5%;
    align-self: flex-end;
    z-index:10000;
}

.mlhImg {
    width: 100%;
}