.aboutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10% 0;
}

.aboutContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutText {
    background-color: white;
    border-radius: 48px;
    width: 85%;
    padding: 36px;
    margin: 0;

    font-weight: 500;
    font-size: clamp(1rem, 1.5vw, 1.25rem);
    font-family: 'Poppins', sans-serif;
    text-align: left;
    color: #24b2c0;
}

.aboutImg {
    width: 85%;
    margin-top: -5%;
}

.aboutTitle {
    margin: 2% 0;

    font-family: 'Poppins',sans-serif;
    font-weight: 900;
    font-size: clamp(1.5rem, 3vw, 3rem);
    font-style: italic;
    display: inline-block;
    color: white;
}

@media screen and (min-width: 600px) {
    .aboutContainer {
        margin: 5% 0;
        flex-direction: row;
    }

    .aboutContent {
        margin-left: 10%;
        width: 50%;
    }

    .aboutText {
        border-radius: 60px;
        padding: 36px;
        width: 100%;
    }

    .aboutImg {
        width: 40%;
        margin-left: -2%;
        margin-top: 0;
    }
}

@media screen and (min-width: 1200px) {
    .aboutText {
        border-radius: 72px;
        padding: 48px;
    }
}
